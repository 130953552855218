import { Platform, PlatformData, PlatformFormat } from "shared/platforms";

export type Collection = {
  _id?: string;
  id?: string;
  contractAddress: string;

  name?: string;
  description?: string;
  slug?: string;

  artBlocksProjectId?: string;
  artBlocksCurationStatus?: string | null;
  artBlocksHeritageCurationStatus?: string | null;
  artBlocksVerticalName?: string;

  artBlocksActive?: boolean;
  artBlocksPaused?: boolean;
  artBlocksComplete?: boolean;

  mintStartsAt?: Date;
  mintState?: MintState;

  supplyMax?: number;

  platform?: Platform;
  platformFormat?: PlatformFormat;
  platformData?: PlatformData;
  tags?: string[];
  artistIds?: string[];
  artistSlugs?: string[];
  artistName?: string;
  artistWalletAddress?: string;

  website?: string;
  imageUrl?: string; // dynamic image url based on collection type & override data
  imageThumbnailUrl?: string; // dynamic image url - thumbnail
  imageLargeUrl?: string; // dynamic image url - high res
  imageProcessedUrl?: string; // image URL pulled from reservoir (pulled from opensea)
  imageOverrideUrl?: string; // return this as the imageUrl if set
  aspectRatio?: string;
  aspectRatioIsVariable?: boolean;

  scriptGenerationAvailable?: boolean; // show generator features (dynamic based on collection type)
  scriptGenerationAvailableOverride?: boolean; // hard coded value to override scriptGenerationAvailable

  rarityDisabled?: boolean; // show rarity features be enabled for the collection (dynamic based on collection size)
  rarityDisabledOverride?: boolean; // hard coded value to disable rarity
  rarityRefreshedAt?: Date;

  openseaSlug?: string;
  openseaRaw?: object;

  stats?: CollectionStats;
  hidden?: boolean;

  refreshedAt?: Date;
  updatedAt?: Date; // Date updated in our DB
  createdAt?: Date; // Date created in our DB

  artistDisplayNotes?: string;
  creativeCredit?: string;
  salesNotes?: string;
  license?: string;
  linkToLicense?: string;
  renderDelay?: number;
  disableSampleGenerator?: boolean;
  videoRenderDelay?: number;
  videoDuration?: number;
  videoFps?: number;
  videoAspectRatio?: number;
  primaryRenderType?: "gif" | "image" | "live" | "video";
  previewRenderType?: "gif" | "image" | "live" | "video";
  generateVideoAssets?: boolean;
  completedAt?: Date;
  scriptTypeAndVersion?: string;
  charitableGivingDetails?: string;
  featuredTokenId?: string;
  featureTokenMediaUrl?: string;
  oobTokens?: Array<{
    id?: string;
    mediaUrl?: string;
  }>;
  maxInvocations?: number;
  invocations?: number;
};

export type CollectionStats = {
  salesOneDay: number;
  salesSevenDays: number;
  salesThirtyDays: number;
  salesTotal: number;
  volumeOneDay: number;
  volumeOneDayChange: number;
  volumeSevenDays: number;
  volumeSevenDaysChange: number;
  volumeThirtyDays: number;
  volumeThirtyDaysChange: number;
  volumeTotal: number;
  floorPrice: number;
  supplyTotal: number;
  ownersTotal: number;
  updatedAt: Date;
  mostRecentMintPrice?: number;
  mostRecentMintTimestamp?: Date;
};

export enum MintState {
  Upcoming = "UPCOMING",
  InProgress = "IN_PROGRESS",
  Complete = "COMPLETE",
}
